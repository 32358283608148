import React, { useState, useEffect } from "react";
import NameListItem from "./NameListItem";

function NameList() {
  const [loadData, setLoadData] = useState(new Date());
  const [nameList, setNameList] = useState([
    {
      id: 1,
      name: {
        title: "mr",
        first: "Brad",
        last: "Gibson",
      },
      location: {
        city: "kilcoole",
      },
      email: "brad.gibson@example.com",
      dob: {
        date: "1993-07-20T09:44:18.674Z",
        age: 26,
      },
      picture: {
        medium: "https://randomuser.me/api/portraits/med/men/75.jpg",
      },
    },
    {
      id: 2,
      name: { title: "Mr", first: "Felix", last: "Thomsen" },
      location: {
        city: "Agerbæk",
      },
      email: "felix.thomsen@example.com",
      dob: {
        date: "1985-03-07T04:40:13.709Z",
        age: 35,
      },
      picture: {
        medium: "https://randomuser.me/api/portraits/med/men/33.jpg",
      },
    },
    {
      id: 3,
      name: { title: "Miss", first: "Suzanna", last: "Neal" },
      location: {
        city: "Salisbury",
      },
      email: "suzanna.neal@example.com",
      dob: {
        date: "1997-10-24T02:22:56.515Z",
        age: 23,
      },
      picture: {
        medium: "https://randomuser.me/api/portraits/med/women/83.jpg",
      },
    },
  ]);

  useEffect(() => {
    fetch("https://randomuser.me/api")
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setNameList((nameList) => [...nameList, responseData.results[0]]); //setNameList required array so we need to put [] on responseData.results[0]
      });
  }, [loadData]); //use [] to run this only one time otherwise it will naver end

  const nameListComponent = () => {
    return nameList.map((aName) => {
      return (
        <NameListItem
          key={aName.id}
          name={`${aName.name.first} ${aName.name.last}`}
          city={aName.location.city}
          email={aName.email}
          birthday={aName.dob.date}
          avatar={aName.picture.medium}
        />
      );
    });
  };

  const addUserHandler = () => {
    setLoadData(new Date());
  };

  return (
    <React.Fragment>
      <div className="container mt-4">
        <button className="btn btn-primary mb-2" onClick={addUserHandler}>
          Add Name
        </button>
        <ul className="list-group">{nameListComponent()}</ul>
      </div>
    </React.Fragment>
  );
}

export default NameList;
