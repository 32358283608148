import React from "react";

function Home() {
  return (
    <div className="container mt-4">
      <h3>Welcome</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Bibendum est
        ultricies integer quis auctor. Pretium lectus quam id leo in vitae
        turpis massa sed. Posuere lorem ipsum dolor sit. Consectetur libero id
        faucibus nisl tincidunt eget. Lacinia quis vel eros donec ac odio
        tempor. Vitae tortor condimentum lacinia quis vel eros donec ac. Aliquam
        vestibulum morbi blandit cursus risus at ultrices mi tempus. Consequat
        ac felis donec et odio pellentesque diam. Ultricies integer quis auctor
        elit sed vulputate mi sit amet. Viverra tellus in hac habitasse.
        Vestibulum sed arcu non odio euismod lacinia at quis risus.
      </p>
    </div>
  );
}

export default Home;